<template>
<div style="width:100%">
  <v-container fluid>
    <v-sheet>
      <v-card flat>
        <v-toolbar flat color="transparent">
          <v-btn @click="cerrar_formulario" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
              <v-row >
                <v-col class="text-center description" :class="(mini == 'xs' || mini == 'sm' )?'font-size-15':'font-size-32'" >
                  <span :style="{'color': colores.secundario }"><b>FORMULARIO RADICACIÓN </b></span>
                  <span  :style="{'color': colores.secundario }"><b>QUEJAS Y RECLAMOS (QR)</b></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-sheet :color="colores.primario" style="padding:1px">
                    <v-sheet flat class="pt-2">
                      <v-toolbar flat><span class="description" :class="(mini == 'xs' || mini == 'sm' )?'font-size-15 align-center':'font-size-24 align-left'" :style="{'color': colores.secundario,  'font-weight': 'normal'}">Información del solicitante (persona que radica la QR)</span></v-toolbar>
                      <v-card-text>
                        <solicitante :form_solicitante="form_solicitante" :rules="rules" :items="items" :loadings="loadings" :colores="colores"></solicitante>
                      </v-card-text>
                    </v-sheet>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <v-sheet :color="colores.primario" style="padding:1px">
                    <v-sheet flat class="pt-2">
                      <v-toolbar flat><span class="description" :class="(mini == 'xs' || mini == 'sm' )?'font-size-15 align-center':'font-size-24 align-left'" :style="{'color': colores.secundario, 'font-weight': 'normal'}">Información del consumidor financiero o cliente que tiene el producto</span></v-toolbar>
                      <v-card-text class="pt-0">
                        <cliente :form_cliente="form_cliente" :rules="rules" :items="items" :loadings="loadings" :colores="colores"></cliente>
                      </v-card-text>
                    </v-sheet>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <v-sheet :color="colores.primario" style="padding:1px">
                    <v-sheet flat>
                      <v-toolbar flat><span class="description" :class="(mini == 'xs' || mini == 'sm' )?'font-size-15 align-center':'font-size-24 align-left'" :style="[{'color': colores.secundario,  'font-weight': 'normal' }]">Información del producto</span></v-toolbar>
                      <v-card-text class="pt-0">
                        <producto :form_producto="form_producto" :form_vehiculo="form_vehiculo" :rules="rules" :items="items" :loadings="loadings" :colores="colores"></producto>
                      </v-card-text>
                    </v-sheet>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-sheet :color="colores.primario" style="padding:1px">
                    <v-sheet flat>
                      <br>
                      <v-toolbar flat>
                        <span class="description" :class="(mini == 'xs' || mini == 'sm' )?'font-size-15 align-center':'font-size-24 align-left'" :style="{'color': colores.secundario, 'font-weight': 'normal' }">Queja o reclamo</span>
                        <v-spacer></v-spacer>
                        <v-row>
                          <v-col cols="12" md="8"></v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="gestion.fecha_requerimiento" disabled :color="colores.primario" label="Fecha de radicacion"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                      <v-card-text class="pt-0">
                        <qr :form_qr="form_qr" :gestion="gestion" :form_files="form_files" :rules="rules" :items="items" :loadings="loadings" :colores="colores"></qr>
                      </v-card-text>
                    </v-sheet>
                  </v-sheet>
                </v-col>
              </v-row>
              <br>
              <v-row >
                <v-col cols="12" md="6" class="text-center">
                  <div style="cursor:pointer;">
                    <v-hover v-slot="{ hover }" >
                        <v-sheet @click="cerrar_formulario" :color="hover? '#B3B3B3': '#B3B3B3'" style="padding: 2px;" >
                          <v-sheet flat v-if="hover"  style="border: 2px solid #B3B3B3" >
                            <v-card-text class="black--text">
                              <span class="roboto" style="font-size:15px;"><b>REGRESAR</b></span>
                            </v-card-text>
                          </v-sheet>
                          <v-sheet v-else flat :color="colores.white" style="border: 2px solid #B3B3B3" >
                            <v-card-text class="black--text">
                              <span class="roboto" style="font-size:15px;"><b>REGRESAR</b></span>
                            </v-card-text>
                          </v-sheet>
                        </v-sheet>
                    </v-hover>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                  <!-- <v-btn @click="validar" block large :color="colores.primario" dark>ENVIAR QR</v-btn> -->
                  <div style="cursor:pointer;">
                    <v-hover v-slot="{ hover }" open-delay="1">
                        <v-sheet @click="validar" :color="hover? colores.primario: colores.primario" style="padding: 3px;" :class="[{ 'on-hover': hover }]">
                          <v-sheet flat v-if="hover" :color="colores.primario">
                            <v-card-text class="black--text" >
                              <span class="roboto" style="font-size:15px;"><b>ENVIAR QR</b></span>
                            </v-card-text>
                          </v-sheet>
                          <v-sheet v-else flat :color="colores.white" >
                            <v-card-text class="black--text">
                              <span class="roboto" style="font-size:15px;"><b>ENVIAR QR</b></span>
                            </v-card-text>
                          </v-sheet>
                        </v-sheet>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <center><v-img src="img/logo1.png" style="width: 229px;height: 46px" ></v-img></center>
                </v-col>
              </v-row>
              <dialogoConfirmar :registro="registro" :colores="colores" :dialogConfirmar="dialogConfirmar" @closed_confirmacion="cerrar_confirmacion" ></dialogoConfirmar>
          </v-form>
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-container>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import solicitante from '@/components/pqrs/solicitanteComponent.vue'
import cliente from '@/components/pqrs/clienteComponent.vue'
import producto from '@/components/pqrs/productoComponent.vue'
import qr from '@/components/pqrs/qrComponent.vue'
import dialogoConfirmar from '@/components/pqrs/dialogo_confirmarComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'

export default {
  name: 'formularioComponent',
  components: {
    solicitante,
    cliente,
    producto,
    qr,
    dialogoConfirmar,
    mensajesSave
  },
  mixins: [recFunciones, recGestiones],
  props: ['rules', 'colores', 'items', 'loadings', 'token'],
  data: () => ({
    registro: null,
    dialogConfirmar: false,
    form_solicitante: { gestion_id: 0, tipo_id: 0, nombre: '', telefono: '', correo: '' },
    form_cliente: { gestion_id: 0, tipo_id: 0, documento_id: 0, identificacion: '', nombre: '', sexo_id: null, lgbtiq_id: null, especial_id: null },
    form_producto: { gestion_id: 0, producto_id: 0, productodigital_id: 0, numero: '', pais_id: null, departamento_id: null, municipio_id: null },
    form_vehiculo: { gestion_id: 0, placa: '' },
    form_qr: { gestion_id: 0, keyform: 0, motivo_id: 0, submotivo_id: 0, descripcion: '', diasext: 0, diasint: 0 },
    gestion: { keyform: 0, id_usuario: null, aplicativo_id: 1, codigo: 0, tipo_id: null, instancia_id: null, canal_id: null, admision_id: null, entecontrol_id: null, motivo_id: 0, submotivo_id: 0, quejaexpre_id: null, descripcion: '', fecha_requerimiento: null, segundos: 0, subestado_id: 7, escalado_id: 7, id_usuariocerro: 0, diasext: 0, diasint: 0, quejaexpres_id: null },
    form_files: { items_archivos: [] },
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  watch: {
    token: {
      immediate: true,
      handler (newVal, oldVal) {
        this.gestion.fecha_requerimiento = this.rec_funciones_fecha_actual()
        const keyform = this.rec_funciones_key()
        this.form_qr.keyform = keyform
        this.gestion.keyform = keyform
        this.items.objMotivoQR = null
        this.items.objSubMotivoQR = null
      }
    }
  },
  computed: {
    mini () {
      return this.$vuetify.breakpoint.name
    }
  },
  methods: {
    validar () {
      // his.dialogo.pregunta = true
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestiones_guardar()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    cerrar_confirmacion () {
      this.dialogConfirmar = false
      this.cerrar_formulario()
    },
    cerrar_formulario () {
      this.$emit('closed_formulario')
    }
  }
}
</script>
<style scoped>
.font-size-15 {
  font-size: 15px;
}
.font-size-32 {
  font-size: 32px;
}

.font-size-24 {
  font-size: 24px;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

</style>
