<template>
  <div style="width:100%">
    <v-row no-gutters>
      <v-col cols="12" md="3" class="text-center">
        <div style="cursor:pointer;">
          <v-hover v-slot="{ hover }" open-delay="1">
            <v-sheet @click.native="onFocus" :color="hover ? colores.primario : colores.primario" style="padding: 3px;"
              :class="[{ 'on-hover': hover }]">
              <v-sheet flat v-if="hover" :color="colores.primario">
                <v-card-text class="black--text">
                  <span class="roboto" style="font-size:15px;">
                    <v-icon>mdi-paperclip</v-icon>ADJUNTAR ARCHIVO
                  </span>
                </v-card-text>
              </v-sheet>
              <v-sheet v-else flat :color="colores.white">
                <v-card-text class="black--text">
                  <span class="roboto" style="font-size:15px;">
                    <v-icon>mdi-paperclip</v-icon>ADJUNTAR ARCHIVO
                  </span>
                </v-card-text>
              </v-sheet>
            </v-sheet>
          </v-hover>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align-self="center" class="text-center" v-if="progress > 0">
        <v-progress-linear v-model="progress" color="blue darken-3"></v-progress-linear>
        {{ progress }}%
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!uploadError">
        Capacidad máxima de archivos hasta 20MB.
      </v-col>
      <v-col v-else>
        <v-alert :value="uploadError" type="error">
          Capacidad máxima de archivos hasta 20MB.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="uploadError2">
        <v-alert type="error">
          Capacidad Maxima de archivos juntos hasta 30MB.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="uploadError3">
        <v-alert type="error">
          Solamente archivos con extencion: pdf, jpg, jpeg, png, mp4, doc, docx, xls, xlsx, bmp, mp3, msg.
        </v-alert>
      </v-col>
    </v-row>
    <br>
    <v-list>
      <template v-for="(item, index) in items_archivos">
        <div :key="index" v-if="item.estado_id === 1">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> {{ item.original }} </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>{{ rec_funciones_bytesToSize(item.tamanio) }}</v-list-item-action-text>
              <v-row no-gutters>
                <v-col cols="12" md="6" align-self="center">
                  <v-icon :color="colores.green">mdi-check-bold</v-icon>
                </v-col>
                <v-col cols="12" md="6" align-self="center">
                  <v-btn @click="eliminar(item.id, index)" icon>
                    <v-icon :color="colores.red">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>{{ bytesTotal }}</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!--
  <v-flex xs6 v-if="false">
    <input style="display:none" type="file" :multiple="false" ref="fileInput" @change="onFileChange">
    <v-text-field single-line v-model="filename" ref="fileTextField" :disabled="true" v-if="false"></v-text-field>
    <v-btn icon @click.native="cancelar"><v-icon color="pink" dark>mdi-restore</v-icon></v-btn>
    <v-btn icon @click.native="onFocus"><v-icon color="blue darken-3" dark>mdi-camera</v-icon></v-btn>
  </v-flex>
</v-layout>
-->
    <input style="display:none" type="file" :multiple="true" ref="fileInput" @change="onFileChange">
    <v-text-field single-line v-model="filename" ref="fileTextField" :disabled="true" v-if="false"></v-text-field>

  </div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import recUpload from '@/js/rec_upload.js'
import axios from 'axios'
export default {
  name: 'uploadComponent',
  props: ['tipo', 'items_archivos', 'urlroute', 'keyform', 'colores'],
  mixins: [recFunciones, recUpload],
  data () {
    return {
      filename: '',
      progress: '0',
      files: [],
      iseliminar: false,
      loadingEliminar: false,
      uploadError: false,
      uploadError2: false,
      uploadError3: false
    }
  },
  created () {
  },
  watch: {
  },
  computed: {
    bytesTotal () {
      let total = 0
      this.items_archivos.map(item => {
        if (item.estado_id === 1) {
          total = total + item.tamanio
        }
      })
      return (total === 0) ? '' : this.rec_funciones_bytesToSize(total)
    }
  },
  methods: {
    eliminar (pId, i) {
      if (this.tipo === 'LOG') {
        this.rec_upload_log(pId, i)
      } else {
        this.rec_upload_gestion(pId, i)
      }
    },
    onFocus () {
      this.$refs.fileInput.click()
    },
    onFileChange ($event) {
      const files = $event.target.files || $event.dataTransfer.files
      if (files[0].type === 'image/jpeg' || files[0].type === 'image/jpg' || files[0].type === 'image/jpeg' || files[0].type === 'image/png' || files[0].type === 'application/pdf' || files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || files[0].type === 'video/mp4' || files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'audio/mpeg' || files[0].type === 'application/msword' || files[0].type === 'application/vnd.ms-excel' || files[0].type === 'image/bmp' || files[0].type === '') {
        this.validarTamaño(files)
      } else {
        this.uploadError3 = true
        setTimeout(() => {
          this.uploadError3 = false
        }, 5000)
      }
    },
    validarTamaño (files) {
      this.sizeFiles = 0
      this.items_archivos.forEach(element => {
        this.sizeFiles += element.tamanio
      })
      this.sizeFiles += files[0].size
      if ((this.sizeFiles / 1024) > 30000) {
        this.uploadError2 = true
        setTimeout(() => {
          this.uploadError2 = false
        }, 3000)
      } else if ((files[0].size / 1024) > 20000) {
        this.uploadError = true
        setTimeout(() => {
          this.uploadError = false
        }, 3000)
      } else {
        this.filename = files[0].name
        this.files = files
        this.CargarFiles()
      }
    },
    CargarFiles () {
      var filedata = new FormData()
      for (var i = 0; i < this.files.length; i++) {
        filedata.append('files[]', this.files[i])
      }
      filedata.append('usuario_id', 0)
      filedata.append('keyform', this.keyform)
      this.iseliminar = false
      this.progress = 0
      var urlroute = this.$store.getters.getUrl + this.urlroute
      axios({
        method: 'POST',
        url: urlroute,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: filedata,
        onUploadProgress: function (e) {
          if (e.lengthComputable) {
            var percentComplete = e.loaded / e.total
            percentComplete = parseInt(percentComplete * 100)
            this.progress = percentComplete
          }
          setTimeout(() => {
            this.progress = 0
          }, 3000)
        }.bind(this)
      }).then(response => {
        this.$emit('refresh_archivos', response.data)
      }, error => {
        console.log('error ' + error)
        if (error.response.status === 422) {
          this.items_errores = error.response.data.errors
        }
        this.dialogo.error = true
      })
    }
  }
}
</script>
