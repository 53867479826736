<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="3" class="pr-1">
      <v-autocomplete v-model="form_cliente.tipo_id" label="Tipo de persona *" :rules="[rules.requerido]" :items="items.tipopersonas" :loading="loadings.loadingData" item-text="descripcion" item-value="id"  :color="colores.primario"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_cliente.documento_id" label="Tipo de documento *" :rules="[rules.requerido]" :items="items.tipodocumentos" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="5">
      <v-text-field v-model="form_cliente.identificacion" label="No. de documento *" maxlength="15" counter="15" :rules="[rules.requerido, rules.numerico]" :color="colores.primario" ></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
      <v-text-field  v-model="form_cliente.nombre" label="Nombre completo / razón social *"  maxlength="50" counter="50" :rules="[rules.requerido]" :color="colores.primario"></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" md="3" class="pr-1">
      <v-autocomplete v-model="form_cliente.sexo_id" label="Sexo" :items="items.tiposexos" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_cliente.lgbtiq_id" label="Perteneces al colectivo LGBTIQ+" :items="items.tiposi" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="5">
      <v-autocomplete v-model="form_cliente.especial_id"  label="Perteneces a un grupo de condición especial" :items="items.grupoespecial" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario"></v-autocomplete>
    </v-col>
  </v-row>

</div>
</template>

<script>

export default {
  name: 'solicitanteComponent',
  components: {
  },
  props: ['form_cliente', 'rules', 'colores', 'items', 'loadings'],
  data: () => ({
    //
  })
}
</script>
