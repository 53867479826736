<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="6" class="pr-1">
      <v-autocomplete v-model="form_solicitante.tipo_id" :rules="[rules.requerido]" :items="items.tiposolicitantes" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="Tipo de solicitante *"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="form_solicitante.nombre" :rules="[rules.requerido]" maxlength="50" counter="50" :color="colores.primario" label="Nombre completo de la persona que radica la QR *"></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" md="6" class="pr-1">
      <v-text-field v-model="form_solicitante.telefono" :rules="[rules.telefono]" maxlength="10" counter="10" :color="colores.primario" label="Teléfono / celular"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="form_solicitante.correo" :rules="[rules.requerido, rules.email]" maxlength="50" counter="50" :color="colores.primario" label="Correo electrónico de la persona que radica la QR *"></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="text-justify description">
        <b>Importante:</b> Por protección de datos, las respuestas que contengan información de clientes serán enviadas al correo electrónico que el cliente tenga registrado en el sistema. Puede actualizarlo a través de la línea de atención o en el portal Mi cuenta. De lo contrario se deberá adjuntar el poder o documento que autorice la solicitud de información por un tercero.
    </v-col>
  </v-row>

</div>
</template>

<script>

export default {
  name: 'solicitanteComponent',
  components: {

  },
  props: ['form_solicitante', 'rules', 'colores', 'items', 'loadings'],
  data: () => ({
    //
  })
}
</script>
