<template>
<div style="width:100%">
  <v-container fluid>
      <v-card flat>
        <v-toolbar flat color="transparent">
          <v-btn @click="cerrar_ventana" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <center><span class="description" :style="{'color': colores.secundario, 'font-size': '20px' }"><b>SEGUIMIENTO QR</b></span></center>
          <v-row >
            <v-col>
              <v-sheet :color="colores.primario" style="padding:1px">
                <v-sheet flat>
                  <v-toolbar class="description" dense flat><span :style="{'color': colores.secundario, 'font-size': 10 }"><b>Ingrese los datos de la QR</b></span></v-toolbar>
                  <v-card-text class="pt-0">
                    <v-form ref="form_buscar">
                      <buscar :radicacion="radicacion" :rules="rules" :items="items" :colores="colores"></buscar>
                    </v-form>
                    <v-row>
                      <v-col cols="12" md="6" class="text-center">
                        <div style="cursor:pointer;">
                          <v-hover v-slot="{ hover }">
                            <v-sheet @click="cerrar_ventana" :color="hover ? '#B3B3B3' : '#B3B3B3'"
                              style="padding: 2px;">
                              <v-sheet flat v-if="hover" style="border: 2px solid #B3B3B3">
                                <v-card-text class="black--text">
                                  <span class="roboto" style="font-size:15px;"><b>REGRESAR</b></span>
                                </v-card-text>
                              </v-sheet>
                              <v-sheet v-else flat :color="colores.white" style="border: 2px solid #B3B3B3">
                                <v-card-text class="black--text">
                                  <span class="roboto" style="font-size:15px;"><b>REGRESAR</b></span>
                                </v-card-text>
                              </v-sheet>
                            </v-sheet>
                          </v-hover>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="text-center">
                        <!-- <v-btn @click="validar" block large :color="colores.primario" dark>ENVIAR QR</v-btn> -->
                        <div style="cursor:pointer;">
                          <v-hover v-slot="{ hover }" open-delay="1">
                          <v-sheet @click="validar" :color="hover? colores.primario: colores.primario" style="padding: 3px;" :class="[{ 'on-hover': hover }]">
                            <v-sheet flat v-if="hover" :color="colores.primario">
                              <v-card-text class="black--text" >
                                <center> <span class="roboto" style="font-size:15px;"><b>Consultar QR</b></span></center>
                              </v-card-text>
                            </v-sheet>
                            <v-sheet v-else flat :color="colores.white" >
                              <v-card-text class="black--text">
                                <center> <span class="roboto" style="font-size:15px;"><b>Consultar QR</b></span></center>
                              </v-card-text>
                            </v-sheet>
                          </v-sheet>
                        </v-hover>
                      </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <center><v-img src="img/logo1.png" style="width: 229px;height: 46px" ></v-img></center>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-sheet>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="showRespuesta && registro">
            <v-col>
              <v-sheet :color="colores.primario" style="padding:1px">
                <v-sheet flat>
                  <v-toolbar dense flat><center><span class="description" :style="{'color': colores.secundario, 'font-size': 10 }"><b>Información de la QR</b></span></center></v-toolbar>
                  <v-card-text class="pt-0">
                    <respuesta :registro="registro" :colores="colores"></respuesta>
                    <br><br>
                    <v-row v-if="registro.subestado_id === 6">
                      <v-col cols="12" class="text-center">
                        <v-btn @click="enviar" :color="colores.primario" dark>Reenviar respuesta de la QR al correo registrado</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-sheet>
              </v-sheet>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </v-container>
<v-layout row justify-center>
  <v-dialog v-model="updated" persistent max-width="450">
  <v-card>
          <v-toolbar class="headline" color="green darken-2" dark>ESTADO CORREO</v-toolbar>
          <v-card-text>
            <br>
            <v-layout row>
              <v-flex xs12 class="pt-5">
                <center>
                  <v-icon dark color="green darken-2" size="30">mdi-check-circle</v-icon>CORREO ENVIADO CORRECTAMENTE
                </center>
                <br>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" dark @click="updated=false">ACEPTAR</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</v-layout>
<v-layout row justify-center>
  <v-dialog v-model="esperar" persistent max-width="450">
    <v-card :color="colores.primario">
        <v-card-text class="pt-4">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</v-layout>
</div>
</template>

<script>
import buscar from '@/components/seguimientos/buscarComponent.vue'
import respuesta from '@/components/seguimientos/respuestaComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
import recBuscar from '@/js/rec_busquedas.js'
import recGestiones from '@/js/rec_gestiones.js'
export default {
  name: 'seguimientoComponent',
  components: {
    buscar,
    respuesta
  },
  mixins: [recBuscar, recFunciones, recGestiones],
  props: ['colores', 'items', 'rules'],
  data: () => ({
    loadingBuscar: false,
    radicacion: { codigo: 0, tipo_id: 0, identificacion: '' },
    showRespuesta: false,
    registro: null,
    updated: false,
    esperar: false
  }),
  methods: {
    validar () {
      if (this.$refs.form_buscar.validate()) {
        this.rec_busquedas_tipos(this.radicacion)
      }
      // this.showRespuesta = true
    },
    cerrar_ventana () {
      this.$emit('closed_seguimiento')
    },
    enviar () {
      this.rec_gestion_reenvio(this.registro)
    }
  }

}
</script>
