<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  })
}
</script>

<style>
@font-face {
  font-family: "Louis-Regular";
  src: local("Louis-Regular"), url(./fonts/Louis-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"), url(./fonts/Roboto-Bold.ttf) format("truetype");
}

</style>
