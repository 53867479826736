<template>
<div style="width:100%">
  <home v-if="showFormulario === 1" :colores="colores" @create_qr="crear_qr" @followup_qr="seguimiento_qr"></home>
  <formulario v-if="showFormulario === 2" :token="token" :rules="rules" :form_qr="form_qr" :items="items" :loadings="loadings" :colores="colores" @closed_formulario="cerrar_formulario"></formulario>
  <seguimiento v-if="showFormulario === 3" :colores="colores" :rules="rules" :items="items" @closed_seguimiento="cerrar_seguimiento"></seguimiento>
</div>
</template>

<script>
import home from '@/components/homes/homeComponent.vue'
import formulario from '@/components/pqrs/formularioComponent.vue'
import seguimiento from '@/components/seguimientos/seguimientoComponent.vue'
import recForm from '@/js/rec_form.js'
export default {
  name: 'Home',
  components: {
    home,
    formulario,
    seguimiento
  },
  mixins: [recForm],
  data: () => ({
    token: 0,
    rules: null,
    colores: { primario: '#cd9803', secundario: '#262626', black: 'black', red: 'red', green: 'green', white: 'white', transparent: 'transparent' },
    showFormulario: 1,
    loadingData: false,
    form_qr: { motivo_id: 0, motivoqr_id: 0, descripcion: '' },
    items: { tipobusquedas: [], tiposolicitantes: [], tipopersonas: [], tipodocumentos: [], tiposexos: [], tiposi: [], grupoespecial: [], productos: [], motivoprincipales: [], tiporequerimientos: [], instanciarecepcion: [], canales: [], admisiones: [], entecontroles: [], motivoduplicados: [], areas: [], paises: [], marcaciones: [], razonllamadas: [], items_registros: [], items_desistimientos: [], items_favorabilidad: [], items_aceptaciones: [], items_rectificaciones: [], items_fallotutela: [], items_causaraizreq: [], items_causalquejas: [], items_marcacionessegui: [], objMotivoQR: null, objSubMotivoQR: null, objTipoQR: null, showMarcacion: false },
    loadings: { loadingData: false }
  }),
  created () {
    this.rules = { requerido: v => !!v || 'Requerido', email: v => (v && /.+@.+\..+/.test(v)) || 'E-mail invalido', long5: v => (v && v.length > 5) || 'Debe ser mayor a 5 digitos', long10: v => (v && v.length >= 10) || 'Debe ser mayor a 10 digitos', igual7: v => (v.length === 0 || v.length === 7) || 'Debe ser igual a 7 digitos', igual10: v => (v.length === 0 || v.length === 10) || 'Debe ser igual a 10 digitos', combocero: v => (v && v !== 0) || 'Requerido', numerico: v => (v.length === 0 || (!isNaN(parseFloat(v)) && v >= 0)) || 'Numero invalido', telefono: v => (v.length === 0 || ((!isNaN(parseFloat(v)) && v >= 0 && (v.length === 7 || v.length === 10)))) || 'Numero invalido' }
    // this.rules = { requerido: v => !!v || 'Requerido', email: v => (v && /.+@.+\..+/.test(v)) || 'E-mail invalido', long5: v => (v && v.length > 5) || 'Debe ser mayor a 5 digitos', long10: v => (v && v.length >= 10) || 'Debe ser mayor a 10 digitos', igual7: v => (v.length === 0 || v.length === 7) || 'Debe ser igual a 7 digitos', igual10: v => (v.length === 0 || v.length === 10) || 'Debe ser igual a 10 digitos', combocero: v => (v && v !== 0) || 'Requerido', numerico: v => (v.length === 0 || (!isNaN(parseFloat(v)) && v >= 0)) || 'Numero invalido' }
    this.rec_form_qr()
  },
  methods: {
    crear_qr () {
      this.token = this.token + 1
      this.showFormulario = 2
    },
    seguimiento_qr () {
      this.showFormulario = 3
    },
    cerrar_formulario () {
      this.showFormulario = 1
    },
    cerrar_seguimiento () {
      this.showFormulario = 1
    }
  }
}
</script>
