import axios from 'axios'
export default {
  methods: {
    rec_paises_id (pPaisId) {
      this.loadingDepartamento = true
      this.items_departamentos = []
      const urlroute = this.$store.getters.getUrl + 'departamentos_pais/' + pPaisId
      axios({
        url: urlroute,
        method: 'GET',
        data: null
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.items_departamentos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDepartamento = false
      })
    }

  }
}
