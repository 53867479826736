import axios from 'axios'
export default {
  methods: {
    rec_upload_escalamiento (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'escalamiento_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_gestion (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_log (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'log_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'gestion_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_log_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'log_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_escalamiento_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'escalamiento_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }

  }
}
