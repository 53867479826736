<template>
<div style="width:100%">
  <v-dialog v-model="dialogConfirmar" persistent max-width="800">
    <v-card>
      <v-card-text class="pt-4">
        <v-sheet :color="colores.primario" style="padding:2px" rounded>
          <v-card flat>
            <v-card-text v-if="registro">
              <center><span :style="{'color': colores.primario, 'font-size': '20px' }"><b>TU QR HA SIDO RADICADA</b></span></center>
              <br>
              <v-row>
                <v-col cols="12" md="6" class="text-center">
                  <v-row no-gutters> <v-col cols="12" class="text-center">NÚMERO DE RADICADO</v-col></v-row>
                  <v-row no-gutters> <v-col cols="12" class="text-center"><b>{{ registro.codigo }}</b></v-col></v-row>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                  <v-row no-gutters> <v-col cols="12" class="text-center">FECHA ESTIMADA DE RESPUESTA</v-col></v-row>
                  <v-row no-gutters> <v-col cols="12" class="text-center"><b>{{ rec_function_replaceall(registro.fechaparacerrarexterno, '00:00:00', '') }}</b></v-col></v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <b>En el correo electrónico que registraste recibirás los</b> <b><span class="text-decoration-underline">datos de radicación de la QR.</span></b>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-justify">
                  La respuesta a la QR se remitirá al cliente a su correo electrónico registrado en el sistema o al correo electrónico indicado en la solicitud por parte del tercero, siempre que el tercero haya adjuntado el (los) documento(s) que soporten la autorización o lo acrediten para realizar el trámite.
                  <br><br>
                  <b>Seguimiento de la QR:</b> puedes hacer seguimiento a tu QR ingresando en la opción "Seguimiento QR". Debes tener a la mano el número de radicado de la QR y el tipo y número de documento del cliente.
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cerrar" :color="colores.primario" dark>CERRAR</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'confirmacionComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['colores', 'dialogConfirmar', 'registro'],
  data: () => ({
    //
  }),
  methods: {
    cerrar () {
      this.$emit('closed_confirmacion')
    }
  }
}
</script>
