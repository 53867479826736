<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="3" class="pr-1">
      <v-text-field :value="rec_function_replaceall(registro.fecha_requerimiento, ' 00:00:00', '')" readonly :color="colores.primario" label="FECHA DE RADICACIÓN"></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="pr-1">
      <v-text-field :value="registro.subestado.descripcion" readonly :color="colores.primario" label="ESTATUS DE LA QR"></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="pr-1">
      <v-text-field :value="rec_function_replaceall(registro.fechaparacerrarexterno, ' 00:00:00', '')" readonly :color="colores.primario" label="FECHA ESTIMADA DE ESPUESTA"></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field :value="registro.fechacerrado" readonly :color="colores.primario" label="FECHA DE RESPUESTA"></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" class="text-center">

    </v-col>
  </v-row>
  <v-card-text style="white-space:pre-wrap;">

        <!-- <div >{{pqr.descripcion}}</div> -->
  </v-card-text>

</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'respuestaComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['colores', 'registro'],
  data: () => ({
    //
  }),
  methods: {
    validar () {

    }
  }
}
</script>
