<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_producto.producto_id" :rules="[rules.requerido]" v-on:input="input_producto(form_producto.producto_id)" :items="items.productos" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="Tipo de producto *"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_producto.productodigital_id"  :rules="[rules.requerido]" :items="items.tiposi" :loading="loadings.loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="¿Es un producto digital? *"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field v-model="form_producto.numero" :rules="[rules.numerico]" :color="colores.primario" label="No. de producto"></v-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_producto.pais_id" :rules="[rules.requerido]" v-on:input="input_paises(form_producto.pais_id)" :items="items.paises" item-text="descripcion" item-value="id" :color="colores.primario" label="País *"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="pr-1">
      <v-autocomplete v-model="form_producto.departamento_id" :rules="[rules.requerido]" v-on:input="input_departamentos(form_producto.departamento_id)" :items="items_departamentos" item-text="descripcion" item-value="id" :color="colores.primario" :loading="loadings.loadingData" label="Departamento *"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4">
      <v-autocomplete v-model="form_producto.municipio_id" :rules="[rules.requerido]" item-text="descripcion" item-value="id" :items="items_ciudades" :color="colores.primario" :loading="loadings.loadingData" label="Municipio *"></v-autocomplete>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" md="3" v-if="showPlaca">
      <v-text-field v-model="form_vehiculo.placa" maxlength="10" counter="10" :rules="[rules.requerido]" :color="colores.primario" label="Placa del vehiculo *"></v-text-field>
    </v-col>
  </v-row>

</div>
</template>

<script>
import recDepartamentos from '@/js/rec_departamentos.js'
import recPaises from '@/js/rec_paises.js'

export default {
  name: 'productoComponent',
  components: {

  },
  mixins: [recPaises, recDepartamentos],
  props: ['colores', 'items', 'loadings', 'form_producto', 'form_vehiculo', 'rules'],
  data: () => ({
    showPlaca: false,
    loadingDepartamento: false,
    items_departamentos: [],
    loadingCiudad: false,
    items_ciudades: []
  }),
  methods: {
    input_producto (pProductoId) {
      this.showPlaca = false
      if (pProductoId === 5 || pProductoId === 7 || pProductoId === 8 || pProductoId === 6) {
        this.showPlaca = true
      } else {
        this.form_producto.placa = ''
      }
    },
    input_paises (pPaisId) {
      this.rec_paises_id(pPaisId)
    },
    input_departamentos (pDepartamentoId) {
      this.rec_departamento_id(pDepartamentoId)
    }

  }
}
</script>
