<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12">
      <v-autocomplete v-model="items.objMotivoQR" return-object :rules="[rules.requerido]" v-on:input="input_motivo(items.objMotivoQR)" :items="items.motivoprincipales"  item-text="descripcion" item-value="id" :color="colores.primario" label="Motivo principal *"></v-autocomplete>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
      <v-autocomplete v-model="items.objSubMotivoQR" :rules="[rules.requerido]" return-object v-on:input="input_submotivo(items.objSubMotivoQR)" :items="items_submotivos" :loading="loadingSubmotivo" item-text="descripcion" item-value="id" :color="colores.primario" label="Motivo de la QR *"></v-autocomplete>
    </v-col>
  </v-row>
  <v-row no-gutters v-if="descripcionqr">
    <v-col cols="12">
      <span :style="{'color': colores.black, 'font-size': 10 }"><b>Descripción motivo de la QR</b></span>
      <v-card-text style="white-space:pre-wrap;" class="pl-0 pt-0">
        <span>{{ descripcionqr }}</span>
      </v-card-text>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
      <v-textarea v-model="form_qr.descripcion" :rules="[rules.requerido]" :color="colores.primario" label="Descripción de la QR (escribir el motivo y detalle de la QR) *" rows="6" maxlength="4500" counter="4500"></v-textarea>
    </v-col>
  </v-row>
  <br>
  <v-row no-gutters>
    <v-col cols="12" class="text-left">
      <uploadFile :keyform="form_qr.keyform" :items_archivos="form_files.items_archivos" :urlroute="'upload_cargar_nuevo'" :tipo="'GESTION'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFile>
    </v-col>
  </v-row>
  <br>
</div>
</template>

<script>
import recListado from '@/js/rec_listado.js'
import uploadFile from '@/components/widgets/uploadComponent.vue'
export default {
  name: 'qrComponent',
  components: {
    uploadFile
  },
  mixins: [recListado],
  props: ['colores', 'items', 'loadings', 'form_qr', 'gestion', 'rules', 'form_files'],
  data: () => ({
    loadingSubmotivo: false,
    items_submotivos: [],
    descripcionqr: ''
  }),
  methods: {
    input_motivo (pMotivoid) {
      this.form_qr.motivo_id = pMotivoid.id
      this.gestion.motivo_id = pMotivoid.id
      this.form_qr.submotivo_id = 0
      this.gestion.submotivo_id = 0
      this.descripcionqr = ''
      this.rec_listado_motivo(pMotivoid.id)
    },
    input_submotivo (pObj) {
      this.form_qr.submotivo_id = pObj.id
      this.gestion.submotivo_id = pObj.id
      this.form_qr.diasext = (pObj.accion === null) ? 10 : pObj.accion.diasext
      this.form_qr.diasint = (pObj.accion === null) ? 10 : pObj.accion.diasint
      this.gestion.diasext = (pObj.accion === null) ? 10 : pObj.accion.diasext
      this.gestion.diasint = (pObj.accion === null) ? 10 : pObj.accion.diasint
      this.descripcionqr = pObj.opcion1
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.form_files.items_archivos.push(data[i])
      }
    }
  }
}
</script>
