import axios from 'axios'
export default {
  methods: {
    rec_form_qr () {
      this.loadings.loadingData = true
      this.items.tiposolicitantes = []
      this.items.tipopersonas = []
      this.items.tipodocumentos = []
      this.items.tiposexos = []
      this.items.tiposi = []
      this.items.grupoespecial = []
      this.items.productos = []
      this.items.paises = []
      const urlroute = this.$store.getters.getUrl + 'form_qr'
      axios({
        url: urlroute,
        method: 'GET',
        data: null
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.items.tiposolicitantes = response.data.tiposolicitantes
        this.items.tipopersonas = response.data.tipopersonas
        this.items.tipodocumentos = response.data.tipodocumentos
        this.items.tiposexos = response.data.tiposexos
        this.items.tiposi = response.data.tiposi
        this.items.grupoespecial = response.data.grupoespecial
        this.items.productos = response.data.productos
        this.items.motivoprincipales = response.data.motivoprincipales
        this.items.paises = response.data.paises
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadings.loadingData = false
      })
    }

  }
}
