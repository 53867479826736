<template>
<div style="width:100%" ref="beginning">
  <v-container fluid>
    <v-sheet rounded>
      <v-card flat>
        <v-card-text>
          <v-card elevation="5">
            <v-img src="img/logo2.png" class="white--text">
            </v-img>
          </v-card>
        </v-card-text>
        <br>
        <v-card-text class="pa-0">
          <v-row no-gutters>
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="8" class="text-center">
                <span style="color:#6e6e6e;font-size:22px;font-weight:300;" class="description">
                En Chevrolet Servicios Financieros nos interesa conocer tu opinión
                <br>
                En este portal puedes radicar tus quejas o reclamos asi como hacer
                seguimiento a tus requerimientos
                </span>
              </v-col>
          </v-row>
        </v-card-text>
        <br>
        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12" md="2" align-self="start">
            </v-col>
            <v-col  cols="12" md="8">
                <v-card flat class="text-center">
                    <span style="color:#262626;font-size:28px;" class="description">
                      <br>
                      <b>¿QUÉ QUIERES HACER?</b>
                    </span>
                </v-card>
                <v-card flat class="text-center">
                  <br>
                  <v-row>
                    <v-col cols="12" md="6">
                      <div style="cursor:pointer;">
                        <v-hover v-slot="{ hover }" open-delay="1">
                          <v-sheet @click="radicar" :color="hover? colores.primario: colores.primario"
                            style="padding: 3px;" :class="[{ 'on-hover': hover }]">
                            <v-sheet flat v-if="hover" :color="colores.primario">
                              <v-card-text class="black--text">
                                <span class="roboto" style="font-size:15px;"><b>Radicar QR</b></span>
                              </v-card-text>
                            </v-sheet>
                            <v-sheet v-else flat :color="colores.white">
                              <v-card-text class="black--text">
                                <span class="roboto" style="font-size:15px;"><b>Radicar QR</b></span>
                              </v-card-text>
                            </v-sheet>
                          </v-sheet>
                        </v-hover>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div style="cursor:pointer;">
                        <v-hover v-slot="{ hover }" open-delay="1">
                          <v-sheet @click="seguimiento" :color="hover? colores.primario: colores.primario" style="padding: 3px;" :class="[{ 'on-hover': hover }]">
                            <v-sheet flat v-if="hover" :color="colores.primario">
                              <v-card-text class="black--text" >
                                <span class="roboto" style="font-size:15px; "><b>Seguimiento QR</b></span>
                              </v-card-text>
                            </v-sheet>
                            <v-sheet v-else flat :color="colores.white" >
                              <v-card-text class="black--text">
                                <span class="roboto" style="font-size:15px;"><b>Seguimiento QR</b></span>
                              </v-card-text>
                            </v-sheet>
                          </v-sheet>
                        </v-hover>
                      </div>
                      <!-- <v-btn @click="seguimiento" outlined :color="colores.primario" dark><span style="color:#000">Seguimiento QR</span></v-btn> -->
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                        <center><v-img src="img/logo1.png" style="width: 229px;height: 46px" ></v-img></center>
                      </v-col>
                    </v-row>
                  <br>
                </v-card>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </v-sheet>
  </v-container>
</div>
</template>

<script>

export default {
  name: 'homeComponent',
  components: {

  },
  props: ['colores'],
  mounted () {
    this.scrollToStart()
  },
  methods: {
    scrollToStart () {
      var element = this.$refs.beginning
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    radicar () {
      this.$emit('create_qr')
    },
    seguimiento () {
      this.$emit('followup_qr')
    }
  }

}
</script>
<style>
  .description{
    font-family: "Louis-Regular";
  }
  .roboto{
    font-family: "Roboto-Bold";
  }
</style>
