<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="3" class="pr-1">
      <v-text-field v-model="radicacion.codigo" :rules="[rules.requerido]" :color="colores.primario" label="* No. DE RADICADO"></v-text-field>
    </v-col>
    <v-col cols="12" md="6" class="pr-1">
      <v-autocomplete v-model="radicacion.tipo_id" :rules="[rules.requerido]" item-value="id" item-text="descripcion" :items="items.tipodocumentos" :color="colores.primario" label="* TIPO DE DOCUMENTO"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field v-model="radicacion.identificacion" :rules="[rules.requerido]" :color="colores.primario" label="* No. DE DOCUMENTO"></v-text-field>
    </v-col>
  </v-row>

</div>
</template>

<script>

export default {
  name: 'buscasComponent',
  components: {
  },
  props: ['colores', 'radicacion', 'items', 'rules'],
  data: () => ({
    //
  }),
  methods: {
  }
}
</script>
