import axios from 'axios'
export default {
  methods: {
    rec_gestiones_datos () {
      this.form_qr.descripcion = this.rec_funciones_mayuscula(this.form_qr.descripcion)
      this.gestion.descripcion = this.rec_funciones_mayuscula(this.form_qr.descripcion)
    },
    rec_gestiones_guardar () {
      this.rec_gestiones_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      const urlroute = this.$store.getters.getUrl + 'micrositio_save'
      const data = { vehiculo: this.form_vehiculo, producto: this.form_producto, cliente: this.form_cliente, solicitante: this.form_solicitante, gestion: this.gestion, micrositio: this.form_qr }
      this.registro = null
      axios({
        url: urlroute,
        method: 'POST',
        data: data
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.registro = response.data
        this.dialogConfirmar = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_reenvio (registro) {
      this.esperar = true
      const urlroute = this.$store.getters.getUrl + 'micrositio_reenvio'
      const data = { id: registro.id, codigo: registro.codigo }
      this.registro = null
      axios({
        url: urlroute,
        method: 'POST',
        data: data
      }).then(response => {
        this.esperar = false
        this.updated = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    }

  }
}
